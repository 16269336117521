
.emptyTrashboxButton {
  text-align: right;
  padding-right: 1rem;
}

.emptyTrashboxRow {
  padding-bottom: 1rem;
}

.emptyTrashboxButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
}